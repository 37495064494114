"use client";

import { useState } from "react";
import { Button } from "~/@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "~/@/components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/@/components/ui/select";
import { Input } from "~/@/components/ui/input";
import { ArrowLeft } from "lucide-react";
import { fetchCreateRule } from "~/api/codegen/liquidityComponents";
import { useWallets } from "~/hooks/use-wallet";
import { toast } from "react-toastify";

const transactionTypes = [
  "Funding",
  "Payout",
  "Withdrawal",
  "Convert",
  "Wallet to Wallet",
];

const criteriaOptions = [
  "Greater than",
  "Greater than or equals to",
  "Less than",
  "Less than or equals to",
  "Is equals to",
  "Is not equals to",
  "Is between",
  "Is not between",
];

const approvers = [
  "Adel Oko",
  "Mazi Obi",
  "Jane Doe",
  "James Smith",
  "Owner Role",
  "Admin Role",
  "Developer Role",
  "Support Role",
];

interface Rule {
  id: number;
  transactionType: string;
  currency: string;
  criteria: string;
  amount: string;
  amountSecond: string;
  requiredApprover: string;
  walletId: string;
}

export default function AddRuleModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const { data: wallets } = useWallets();
  const [currentRule, setCurrentRule] = useState<Rule>({
    id: 0,
    transactionType: "Wallet to Wallet",
    currency: "USD",
    criteria: "Greater than",
    amount: "",
    amountSecond: "",
    requiredApprover: "James Smith",
    walletId: "",
  });

  const handleAddRuleToDb = async () => {
    const updatedRule = {
      ...currentRule,
      walletId: wallets?.CORPORATE?.USD?.id ?? "",
    };

    const res = await fetchCreateRule({
      body: updatedRule,
    });

    if (res) {
      resetForm();
      toast.success("Rule added successfully");
      onClose();
    }
  };

  const resetForm = () => {
    setCurrentRule({
      id: 0,
      transactionType: "Wallet to Wallet",
      currency: "USD",
      criteria: "Greater than",
      amount: "",
      amountSecond: "", // Add this line
      requiredApprover: "James Smith",
      walletId: "",
    });
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader className="flex flex-row items-center border-b pb-4">
          <Button
            variant="ghost"
            size="icon"
            className="absolute left-4"
            onClick={onClose}
          >
            <ArrowLeft className="h-4 w-4" />
          </Button>
          <DialogTitle className="flex-1 text-center text-xl font-semibold">
            Add Rule
          </DialogTitle>
        </DialogHeader>

        <div className="space-y-6 py-4">
          <div className="grid gap-6 md:grid-cols-2">
            {/* Transaction Type */}
            <div className="space-y-2">
              <label className="text-sm font-medium">Transaction Type</label>
              <Select
                value={currentRule.transactionType}
                onValueChange={(value) =>
                  setCurrentRule({ ...currentRule, transactionType: value })
                }
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select transaction type" />
                </SelectTrigger>
                <SelectContent>
                  {transactionTypes.map((type) => (
                    <SelectItem key={type} value={type}>
                      {type}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            {/* Currency */}
            <div className="space-y-2">
              <label className="text-sm font-medium">Currency</label>
              <Select
                value={currentRule.currency}
                onValueChange={(value) =>
                  setCurrentRule({ ...currentRule, currency: value })
                }
              >
                <SelectTrigger>
                  <SelectValue>
                    <div className="flex items-center gap-2">
                      <span>🇺🇸 USD</span>
                    </div>
                  </SelectValue>
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="USD">
                    <div className="flex items-center gap-2">
                      <span>🇺🇸 USD</span>
                    </div>
                  </SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>

          {/* Criteria */}
          <div className="space-y-2">
            <label className="text-sm font-medium">Criteria</label>
            <Select
              value={currentRule.criteria}
              onValueChange={(value) =>
                setCurrentRule({ ...currentRule, criteria: value })
              }
            >
              <SelectTrigger>
                <SelectValue placeholder="Select criteria" />
              </SelectTrigger>
              <SelectContent>
                {criteriaOptions.map((option) => (
                  <SelectItem key={option} value={option}>
                    {option}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            {currentRule.criteria === "Is between" ||
            currentRule.criteria === "Is not between" ? (
              <div className="mt-2 flex items-center gap-2">
                <Input
                  type="text"
                  placeholder="0.00"
                  value={currentRule.amount}
                  onChange={(e) =>
                    setCurrentRule({ ...currentRule, amount: e.target.value })
                  }
                />
                <span className="text-sm">and</span>
                <Input
                  type="text"
                  placeholder="0.00"
                  value={currentRule.amountSecond}
                  onChange={(e) =>
                    setCurrentRule({
                      ...currentRule,
                      amountSecond: e.target.value,
                    })
                  }
                />
              </div>
            ) : (
              <Input
                type="text"
                placeholder="0.00"
                value={currentRule.amount}
                onChange={(e) =>
                  setCurrentRule({ ...currentRule, amount: e.target.value })
                }
                className="mt-2"
              />
            )}
          </div>

          {/* Require */}
          <div className="space-y-2">
            <label className="text-sm font-medium">Require</label>
            <Select
              value={currentRule.requiredApprover}
              onValueChange={(value) =>
                setCurrentRule({ ...currentRule, requiredApprover: value })
              }
            >
              <SelectTrigger>
                <SelectValue placeholder="Select approver" />
              </SelectTrigger>
              <SelectContent>
                {approvers.map((approver) => (
                  <SelectItem key={approver} value={approver}>
                    {approver}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </div>

        <div className="flex justify-between border-t pt-4">
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button
            onClick={handleAddRuleToDb}
            className="border border-blue-200 bg-blue-50 text-blue-600 hover:bg-blue-100"
          >
            Add Rule
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
